<template>

    <div class="overflow-hidden flex flex-col relative w-2/5 mr-1 h-full mb-2" :class="{'h-1/3 mb-2': billing && !mobile, 'w-2/5 mr-1': !billing && !mobile, 'box': !mobile, 'box bg-filters w-full': mobile}">

        <Loader :loading="loading" />

        <div class="flex flex-row justify-start items-center">
            <span class=" mr-auto" :class="{'uppercase box-title': !mobile, 'uppercase text-font-gray font-semibold text-sm': mobile}">tnr</span>
            <i class="mdi" :class="{'mdi-arrow-top-right text-success': tnr_data.tnr_objective > 0, 'mdi-arrow-bottom-right text-danger': tnr_data.tnr_objective < 0}"></i>
            <router-link v-if="!mobile" class="header-btn" :to="'tnr'">
                <i class="mdi mdi-ray-start-arrow"></i>
            </router-link>
            <div v-else class="h-6 w-6 bg-aux bg-opacity-25 rounded-lg flex flex-col justify-center items-center" @click="goTnr()">
                <i class="mdi mdi-ray-start-arrow text-aux"></i>
            </div>
        </div>

        <div class="flex  flex-row justify-start items-center">
            <div class="h-full flex flex-col justify-center items-start">
                <span class="font-bold mb-2" :class="{'text-base': mobile, 'text-xl': !mobile}">{{tnr_data.TNR | reduceBigNumbers()}}€</span>
                <span class="text-font-gray" :class="{'text-xs': mobile, 'text-sm': !mobile}">vs Obj <span :class="{'text-good': tnr_data.tnr_objective > 0, 'text-danger': tnr_data.tnr_objective <= 0}"><span v-if="tnr_data.tnr_objective > 0">+</span>{{tnr_data.tnr_objective | numberFormat(2)}}%</span></span>
            </div>

            <div class="h-full flex-1 ml-2 flex flex-row justify-end items-center">
                <div :class="{'h-12 w-12': !mobile, 'h-12 w-12 mr-4': mobile}">
                    <chart-gauge :data="[tnr_data.tnr_objective,0]" />
                </div>
            </div>

        </div>
        
    </div>

</template>

<script>

import { state } from '@/store';
import Loader from '../../Globals/Loader.vue';


export default {
    props: ["tnrnew", 'mobile', 'billing'],
    components: { Loader },
    data() {
        return {
            loading: false,
            tnr_data: false
        };
    },
    methods: {
        load() {
            this.loading = true;

            // if(state.rolSelected.role_type_link != 'presidencia'){

                this.axios.get("projects_management/tnr", { params: this.params }).then(response => {
                    this.tnr_data = response.data.data;
                });

            // }
            
            this.loading = false;
        },
        goTnr(){
            this.$router.push({name:'TnrMobile'})
        }
    },
    computed: {
        params() { return state.globalParams; },
    },
    watch: {
        params() { this.load(); }
    },
    mounted() { this.load(); },
}

</script>